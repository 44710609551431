var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AdminTemplate',[_c('el-col',{attrs:{"span":12,"offset":6}},[_c('div',{staticClass:"mg-y-2 text-right"},[(_vm.step.qId == 16)?_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.exportData}},[_vm._v(" Export ")]):_vm._e()],1),_c('h1',{staticClass:"text-center mg-t-3 mg-b-1"},[_vm._v("จัดลำดับคะแนน (TOP 10)")]),_c('div',[_vm._l((_vm.userList),function(data,index){return _c('el-row',{key:index,staticClass:"mg-y-5"},[_c('el-col',{attrs:{"span":2}},[_c('div',{staticClass:"w-100 m-h-20"},[(
                data.old > 0 &&
                data.old != data.rating &&
                data.old != index + 1 &&
                data.rating > 0
              )?_c('span',[_vm._v("จาก "+_vm._s(data.old))]):_vm._e()])]),_c('el-col',{attrs:{"span":1}},[_c('div',{staticClass:"m-h-20",class:data.old < data.rating ? 'text-danger' : 'text-success'},[(
                data.old > 0 &&
                data.old != data.rating &&
                data.old != index + 1 &&
                data.rating > 0
              )?_c('span',[(data.old < data.rating && _vm.step.qId > 1)?_c('i',{staticClass:"fas fa-chevron-circle-down"}):_vm._e(),(data.old > data.rating && _vm.step.qId > 1)?_c('i',{staticClass:"fas fa-chevron-circle-up"}):_vm._e()]):_vm._e()])]),_c('el-col',{attrs:{"span":3}},[_vm._v(" ลำดับที่ "+_vm._s(index + 1)+" ")]),_c('el-col',{staticClass:"m-h-20",attrs:{"span":1}},[(data.isPoint == 1000)?_c('span',[_c('i',{staticClass:"fas fa-fire text-danger"})]):_vm._e()]),_c('el-col',{attrs:{"span":17}},[_c('div',{staticClass:"is-flex js-between border-bottom",class:index < 3 ? 'border-danger' : ''},[_c('div',[_vm._v(" "+_vm._s(data.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("comma")(data.point))+" ")])])])],1)}),_c('el-row',[_c('el-col',{staticClass:"mg-t-3",attrs:{"span":12,"offset":6}},[_c('el-button',{staticClass:"w-100",attrs:{"type":"primary"},on:{"click":function($event){_vm.step.qId == 16 ? _vm.endRoom() : _vm.nextStep()}}},[_vm._v(_vm._s(_vm.step.qId == 16 ? "จบการทำข้อสอบ" : "ทำข้อสอบข้อต่อไป"))])],1)],1)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }